import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import './assets/css/App.css';
import { Provider } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
import store from './store/index';
import './style.css';
import { ChakraProvider } from '@chakra-ui/react';
import initialTheme from './theme/theme'; //  { themeGreen }
import TermsCondition from './components/Terms&Condition/TermsCondition';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import CookiePolicy from './components/CookiePolicy/CookiePolicy';
import HomePage from './components/Dummy/HomePage';
import JobApply from './tscomponents/jobs/JobApply';
import JobList from './tscomponents/jobs/JobList';

export default function Main() {
  const [currentTheme, setCurrentTheme] = useState(initialTheme);

  return (
      <Provider store={store}>
        <ChakraProvider theme={currentTheme}>
          <Routes>
            <Route index path="/terms-of-use" element={<TermsCondition />} />
            <Route index path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route index path="/cookie-policy" element={<CookiePolicy />} />
            <Route path="/job" element={<JobApply />} />
            <Route path="/" index element={<JobList />} />
            <Route path="*" element={<HomePage />} />
          </Routes>
        </ChakraProvider>
      </Provider>
  );
}
