import {  Flex,Heading
} from '@chakra-ui/react';
import JobApplyLayout from '../../layouts/auth/types/JobApplyLayout';
import ViewRecentJobsButton from '../../tscomponents/common/components/ViewRecentJobsButton';

function HomePage() {


  return (
    <JobApplyLayout  cardTop={{ base: '10px', md: '14vh' }} cardBottom={'100px'} mx="0px" >
    <Flex
      alignItems="center"
      justifyContent="center"
      fontFamily="Poppins"
      mt="2rem"
    >
      <Flex
        alignItems="center"
        width="50%"
        height="100%"
        justifyContent="center"
        direction={'column'}
      >
          <Heading
            fontSize={'xl'}
            fontFamily="Poppins"
            mb="4"
            textAlign="center"
          >
            Candidate Application
          </Heading>
          <ViewRecentJobsButton />
      </Flex>
    </Flex>
    </JobApplyLayout>
  );
}

export default HomePage;
