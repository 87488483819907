import axios from './axios';
import { serviceUrls } from './serviceUrls';


export const getRecentPublicJobs = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().getRecentPublicJobs, data, config);
};


export const fetchJobDetailedPublicData = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().viewJobDataPublic, data, config);
};

export const submitJobApplication = (
  form,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.postForm(serviceUrls().submitJobApplication, form, config);
};
