export const serviceUrls = (urlVar = null) => {
  return {
    tokenApi: `/v1/api/token`,
    dummyInfo: `/v1/api/utils/info`,
    jobListingApi: `/v1/api/jobs/list`,
    getRecentPublicJobs: `/v1/api/business/job/recent`,
    viewJobDataPublic: `/v1/api/business/job/job_full_data_public`,
    submitJobApplication: `/v1/api/business/job/apply`,
    defaultQuestions: '/v1/api/admin/questions/get-default-questions',
    jobQuestions: '/v1/api/admin/questions/get-job-questions',
    submitScreeningQuestions: '/v1/api/admin/questions/create', 
  };
};
