import React, { useEffect, useState } from 'react';
import {Flex, Heading, Text, useToast, Spinner, Link, Spacer, useDisclosure, Button} from '@chakra-ui/react';
import JobApplyLayout from '../../layouts/auth/types/JobApplyLayout';
import { Editor } from "@tinymce/tinymce-react";
import { fetchJobDetailedPublicData } from '../../services/jobService';
import ViewRecentJobsButton from '../common/components/ViewRecentJobsButton';
import { IoMdCheckmarkCircle } from 'react-icons/io';
import JobApplyPanel from './JobApplyPanel';


function JobApply() {
  const toast = useToast();
  const [jobPublicUrl, setJobPublicUrl] = useState(null)
  const [jobApplySource, setJobApplySource] = useState('candidateapplication.com')
  const [isJobDetailsLoading, setIsJobDetailsLoading] = useState(false);
  const { isOpen: isJobApplyPanelOpen, onOpen: onJobApplyPanelOpen, onClose: onJobApplyPanelClose } = useDisclosure();
  const [isApplied, setIsApplied] = useState(false);
  const [selectedJob, setSelectedJob] = useState({});

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if(urlParams.has('id') && urlParams.get('id')){
      setJobPublicUrl(urlParams.get('id'))
    }
    if(urlParams.has('source') && urlParams.get('source')){
      setJobApplySource(urlParams.get('source'))
    }
  }, [])

  useEffect(() => {
     if (jobPublicUrl){
        loadJobDetails();
     }
  }, [jobPublicUrl])

  const loadJobDetails = () => {
    setIsJobDetailsLoading(true);
    fetchJobDetailedPublicData({job_public_url: jobPublicUrl})
      .then(res => {
        if (res.result && res.data[0]) {
          setSelectedJob(res.data[0]);
        }
      })
      .catch(err => {
        toast({
          title: 'Unable to get job details', 
          description: err.toString(),
          status: 'error', 
          isClosable: true, 
          duration: 5000
      });
        setIsJobDetailsLoading(false);
      });
  };

  const JobDetailsLoader = () => {
    if(isJobDetailsLoading){
      return (
        <Flex direction={'column'} justifyContent={'center'} alignItems={'center'}>
            <Spinner />
            <Text>Loading job details</Text>
        </Flex>
      )
    }
  }

  const renderJobDetails = () => {
    if (isApplied){
      return null
    }

    return (
      <Flex width={'100%'} direction={'column'}>
        <Flex mb={'30px'} alignItems={'center'}>
          <Heading fontSize="30px" mb="10px">
            {selectedJob.job_title}
          </Heading>
          <Spacer />
          <Button
            color={'white'} p={'6px 30px'} borderRadius={10} mr={5}
            bg={'blue.500'} fontSize={'md'} onClick={onJobApplyPanelOpen}
          >
            Apply
          </Button>
          <ViewRecentJobsButton />      
        </Flex>

        <Text hidden={selectedJob?.job_description || isJobDetailsLoading} fontSize={'16px'}>Looks like this job does not have any details. Feel free to apply or browse other jobs.</Text>

        <Editor
          tinymceScriptSrc={'/tinymce/tinymce.min.js'}
          value={selectedJob.job_description || ""}
          disabled={true}
          onInit={() => {
            setIsJobDetailsLoading(false);
          }}
          init={{
              branding: false,
              statusbar: false,
              toolbar: false,
              menubar: false,
              plugins: 'autoresize',
              content_style: "body {margin: 0; padding: 0}"
          }}
        />
      </Flex>
    )
  }

  const renderApplied = () => {
    if (!isApplied){
      return null
    }

    return (
      <Flex justifyContent={'center'} alignItems={'center'} direction={'column'}>
        <IoMdCheckmarkCircle fontSize={'50px'} color='gray' />
        <Heading mb={5}>Thank You</Heading>
        <Text fontSize={'20px'} mb={5}>
          Your application has been submitted successfully for the position of {selectedJob?.job_title} at <b>{selectedJob?.business?.business_name}</b>.
        </Text>
        <ViewRecentJobsButton />
      </Flex>
    )
  }

  const onJobApplySuccess = () => {
    setIsApplied(true)
  }

  return (
    <JobApplyLayout  cardTop={{ base: '10px', md: '14vh' }} cardBottom={'100px'} mx="0px" >
        <Flex zIndex="2" borderRadius="15px" direction={'column'}>

          <JobDetailsLoader />
          {renderApplied()}
          {renderJobDetails()}
          <JobApplyPanel
            selectedJob={selectedJob}
            onJobApplySuccess={onJobApplySuccess}
            onJobApplyPanelClose={onJobApplyPanelClose}
            onJobApplyPanelOpen={onJobApplyPanelOpen}
            isJobApplyPanelOpen={isJobApplyPanelOpen}
            jobApplySource={jobApplySource}
          />
        </Flex>
    </JobApplyLayout>
  );
}

export default JobApply;
