import axios from 'axios';
import {
  getSessionId,
  getEmailId,
  getAuthToken,
  clearLocalStorage,
} from '../utils/localStorageIndex';
// import { getSessionId, getEmailId, getAuthToken, clearAppSessionData } from '../utils/localStorageIndex';

function setBaseUrl() {
  var baseURL;
  if (!baseURL) {
    baseURL = 'http://localhost:8012/';
  }
  return baseURL;
}

// Generate axios instance
const instance = axios.create({
  // Change 1: Change below baseurl
  // baseURL: "https://candidateapplication.com",
  // baseURL: "http://ec2-35-171-26-31.compute-1.amazonaws.com",
  baseURL: process.env.REACT_APP_TALENT_STRAP_API_URL,
  // baseURL: setBaseUrlFixed(),
});

instance.interceptors.request.use(
  async requestConfig => {
    let token = await getAuthToken(); // Retrieve the token
    if (token && token.trim().length > 0) {
      requestConfig.headers['Authorization'] = `Bearer ${token}`; // Use the token in the request headers
    }
    return requestConfig;
  },
  error => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  response => {
    return response.data;
  },
  error => {
    if (error.response.status === 401) {
      clearLocalStorage();
      window.location = '/';
    } else if (error.response.status === 404) {
      window.location = '/';
    }else if (error.response.status >= 400 && error.response.status < 500) {
      return {'message': error.response?.data?.detail, 'status': error.response?.status}
    }
    throw error;
    // return Promise.reject(error.response);
  }
);

export default instance;
