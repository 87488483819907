import React, { useEffect, useState } from 'react';
import {Flex, Text, Button, useToast, Textarea, Input,
  Radio, RadioGroup, Stack, Drawer, DrawerContent, DrawerHeader, DrawerCloseButton} from '@chakra-ui/react';
import { isObjectEmpty, jobApplyStandardFields } from '../../utils/common';
import { submitJobApplication } from '../../services/jobService';
import { fetchJobQuestions } from '../../services/questionServices';


function JobApplyPanel(props) {
  const {selectedJob} = props;
  const toast = useToast();
  const [formQuestions, setFormQuestions] = useState(
    {
      'standard': jobApplyStandardFields,
      'custom': [] // coming from API in loadJobQuestions()
    }
  )
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (!isObjectEmpty(selectedJob)){
       loadJobQuestions();
    }
 }, [selectedJob])

  const loadJobQuestions = () => {
    fetchJobQuestions({'job_uuid': selectedJob.job_uuid})
    .then( res => {
        setFormQuestions({...formQuestions, custom: res});
    })
    .catch( err =>  {
        toast({
            title: 'Unable to get job questions', 
            description: err.toString(),
            status: 'error', 
            isClosable: true, 
            duration: 5000
        });
    })
  }

  const renderStandardQuestions = () => {
    return formQuestions.standard.map( (question, index) => {
          return (
            <Flex
              width={'100%'}
              key={index}
              p={'15px 10px'}
              direction={'column'}
              mt={3}
            >
              <Flex>
                {question.is_required && <Text color={'red'} mr={1}>*</Text> }
                <Text as='b' mb={2} fontSize={'18px'}>{question.title}</Text>
              </Flex>

              {question.type == 'text' ?
                <Flex alignItems={'center'}>
                    <Input isRequired={question.is_required} name={question.name} width={'400px'} />
                </Flex>
                : null 
              }

              {question.type == 'file' ?
                <Flex alignItems={'center'}>
                    <Input
                      borderRadius={0}
                      pl={0}
                      border={0}
                      isRequired={question.is_required}
                      name={question.name}
                      type={'file'}
                    />
                </Flex>
                : null 
            }
            
            </Flex>
          )
        })  
  }

  const renderCustomQuestions = () => {
    return formQuestions.custom.map( (question, index) => {
      return (
        <Flex
          width={'100%'}
          key={index}
          p={'15px 10px'}
          direction={'column'}
          mt={3}
        >           
            <Flex>
              <Text color={'red'} mr={1}>*</Text>
              <Text as='b' mb={3} fontSize={'18px'}>{question.question_text}</Text>
            </Flex>

            {question.question_type.code == 'boolean' ?
                <Flex alignItems={'center'}>
                    <RadioGroup                    
                      data-is-custom-field={true}
                    >
                      <Stack>
                        {['true', 'false'].map(option => {
                          return (
                            <Radio size='lg' isRequired={true} value={option} name={question.question_text}>
                                {option == 'true' ? 'Yes' : 'No'}
                            </Radio>
                          )
                        })}
                      </Stack>
                    </RadioGroup>
                </Flex>
                : null 
            }

            {question.question_type.code == 'file' ?
                <Flex alignItems={'center'}>
                    <Input
                      borderRadius={0}
                      pl={0}
                      border={0}
                      type={'file'}
                      isRequired={question.question_is_mandatory}
                      data-is-custom-field={true}
                      name={question.question_text}/>
                </Flex>
                : null 
            }
                
            {question.question_type.code == 'subjective' ?
                <Flex alignItems={'center'}>
                    <Textarea
                      rows={8}
                      isRequired={question.question_is_mandatory}
                      data-is-custom-field={true}
                      name={question.question_text}/>
                </Flex>
                : null 
            }

            {question.question_type.code == 'number' ?
                <Flex alignItems={'center'}>
                    <Input
                      type='number'
                      width={'400px'}
                      onKeyDown={e => {
                        // disable arrow keys on number inputs to avoid increase/decrease number
                        // unintentionally when user wants to scroll the page.
                        if (['ArrowUp', 'ArrowDown', '-'].includes(e.key)){
                          e.preventDefault()
                        }
                      }}
                      isRequired={question.question_is_mandatory}
                      data-is-custom-field={true}
                      name={question.question_text}/>
                </Flex>
                : null 
            }

            {question.question_type.code == 'multiplechoice' ?
                  <RadioGroup                    
                    data-is-custom-field={true}
                  >
                    <Stack>
                      {question.question_options.map(option => {
                        return (
                          <Radio size='lg' isRequired={question.question_is_mandatory} value={option} name={question.question_text}>
                              {option}
                          </Radio>
                        )
                      })}
                    </Stack>
                  </RadioGroup>
                : null 
            }
        </Flex> 
      )
    })
  }

  const renderFormQuestions = () => {

    return (
        <Flex direction={'column'} id={'apply'}>
          <Text ml={15}>Questions marked with asterisk are required.</Text>
          <Input name='job_apply_source' hidden value={props.jobApplySource} />
          {renderStandardQuestions()}
          {renderCustomQuestions()}
        </Flex>
    )
  }

  const renderFormActionButtons = () => {
    return (
        <Flex mt={15}>
            <Button
                type={'submit'} size={'lg'} colorScheme='blue'
                isLoading={isFormSubmitting} loadingText='Submitting...'
            >
                Submit
            </Button>
        </Flex>
    )
  }

  const validateRequiredQuestions = (form) => {
    let isRequiredFieldsFilled = true
    formQuestions.standard.forEach(question => {
      if (question.type == 'file' && question.is_required && !form.get(question.name).name){
        isRequiredFieldsFilled = false
      }
      else if (!form.get(question.name)  && question.is_required){
        isRequiredFieldsFilled = false
      }
    })

    const customRequiredQuestions = formQuestions.custom.filter(question => question.question_is_mandatory)
    customRequiredQuestions.forEach(question => {
      if (question.question_type.code == 'file' && !form.get(question.question_text).name){
        isRequiredFieldsFilled = false
      }
      else if (!form.get(question.question_text)){
        isRequiredFieldsFilled = false
      }
    })
 
    if (!isRequiredFieldsFilled){
      setErrorMessage('Please fill out all of the required questions')
      return false
    }

    return true
  }

  const submitForm = (e) => {
    e.preventDefault()
    const form = new FormData(e.target)

    // get all custom fields and attach it to a single field as strigified JSON
    const customFields = []
    formQuestions.custom.map(question => {
      for (const [fieldName, fieldValue] of form.entries()) {

        // handle file fields
        if(fieldName == question.question_text && question.question_type.code == 'file'){
          customFields.push({name: fieldName, value: fieldValue.name, type: question.question_type.code, filename: fieldValue.name})

          // attach all files into a files field
          form.append('files', fieldValue)
          
        // non file fields
        }else if (fieldName == question.question_text){
          if(question.question_type.code == 'number'){
            customFields.push({name: fieldName, value: Math.abs(fieldValue), type: question.question_type.code})
          }else{
            customFields.push({name: fieldName, value: fieldValue, type: question.question_type.code})
          }
        }
      }
    })

    form.set('custom_fields', JSON.stringify(customFields))
    form.set('job_uuid', selectedJob.job_uuid)

    // do manual validation
    if(!validateRequiredQuestions(form)){
      return false
    }

    setIsFormSubmitting(true);

    submitJobApplication(form)
    .then( res => {
        if (res?.status && res?.status >= 400 && res?.status < 500){
          toast({
            title: 'Unable to submit your job application.',
            description: res?.message,
            status: 'error',
            duration: 5000
          });          
        } else {
          toast({
            title: 'You have applied succesfully.',
            status: 'success',
            duration: 5000
          });
          props.onJobApplySuccess()
          props.onJobApplyPanelClose()
        }
        setIsFormSubmitting(false);        
    })
    .catch( err =>  {
        setIsFormSubmitting(false);
        toast({
            title: 'Unable to submit your job application', 
            description: err.toString(),
            status: 'error', 
            isClosable: true, 
            duration: 5000
        });
    })

    setErrorMessage(null);
    return false
  }

  return (
    <Drawer size={'xl'} isOpen={props.isJobApplyPanelOpen} placement="right" onClose={props.onJobApplyPanelClose}>
      <DrawerContent pl={5} pt={5} overflow={'auto'} pb={20}>
        <DrawerHeader mb={5} pl={2}>Apply to {selectedJob?.job_title}</DrawerHeader>
        <DrawerCloseButton />
        <Flex direction={'column'}>
          <form onSubmit={submitForm}>
            {renderFormQuestions()}
            {errorMessage && <Text fontSize={'16px'} mt={10}>{errorMessage}</Text> }
            {renderFormActionButtons()}
          </form>
        </Flex>
      </DrawerContent>
    </Drawer>
  );
}

export default JobApplyPanel;
