const isObjectEmpty = (obj) => {
    return obj === undefined || obj === null || Object.keys(obj).length == 0;
}

const toTitleCase = (str) => {
    return str.toLowerCase().split(' ').map( word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }

export { isObjectEmpty, toTitleCase }

export const jobApplyStandardFields = [
  {
    'title': 'First Name',
    'name': 'first_name',
    'is_required': true,
    'type': 'text'
  },
  {
    'title': 'Last Name',
    'name': 'last_name',
    'is_required': true,
    'type': 'text'
  },
  {
    'title': 'Email',
    'name': 'email',
    'is_required': true,
    'type': 'text'
  },
  {
    'title': 'Linkedin Profile URL',
    'name': 'linkedin',
    'is_required': false,
    'type': 'text'
  },
  {
    'title': 'Attach Resume',
    'name': 'resume',
    'is_required': true,
    'type': 'file'
  },
]