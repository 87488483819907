import { Link } from "@chakra-ui/react"

const ViewRecentJobsButton = () => {
    return <>
      <Link color={'blue.500'} fontSize={'lg'} href={'/'}> 
        Browse Jobs
      </Link>
    </>
  }

export default ViewRecentJobsButton